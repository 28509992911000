
.rows {
    margin: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rows img {
    object-fit: contain;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 5px white solid;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px 0px;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}

.rows &:hover img {
    border: 5px solid;
}

@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px var(--light-navy);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 25px 15px 0px var(--navy);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 5px 15px 0px var(--light-navy);
        transform: translatey(0px);
    }
}

@media screen and (max-width:768px) {
    .rows img {
        width: 100px;
        height: 100px;
    }
}